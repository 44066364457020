import React, { Component } from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store/rootStore";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Form from "./Views/Form";
import Verify from "./Views/Verify";
import Toolbar from "@material-ui/core/Toolbar";
import FormLanding from "./Views/FormLanding";
import FormError from "./Views/FormError";
import { MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import { theme as importedTheme } from './theme';

// form preview
import FormPreviewLanding from "./Views/FormPreviews/FormPreviewLanding";
import FormPreview from "./Views/FormPreviews/FormPreview";
import BulkAssessment from "./Views/BulkAssessment/BulkAssessment";
import Amplify from "@aws-amplify/core";
import awsconfigGG from "./auth/awsConfig";
import FormWaiting from "./Views/FormWaiting";


const uuid = require("uuid");

Amplify.configure(awsconfigGG);

// Modify existing Theme or create new one
const customTheme = createMuiTheme({
  ...importedTheme
});

class App extends Component {
  createAppBar = () => {
    return (
      <nav
        id="main-header"
        style={{ zIndex: "-100", position: "unset" }}
        className="navbar navbar-expand-lg navbar-light fixed-top text-right "
      >
        <div
          className="container"
          style={{ maxWidth: 1500, textAlign: "right" }}
        >
          <a className="navbar-brand" href="https://www.workflow86.com/">
            <img src="/images/w86_logo.png" width="40" alt="" />
          </a>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto"></ul>
          </div>
        </div>
      </nav>
    );
  };

  createFooter = () => {
    return (
      <div className="footer" style={{ backgroundColor: "rgb(44,44,44)" }}>
        <Toolbar></Toolbar>
      </div>
    );
  };

  render() {
    return (
      <MuiThemeProvider theme={customTheme}>
        <Provider store={store}>
          <Router>
            <MuiThemeProvider theme={customTheme}>
              <Switch>
                <Route exact path="/" component={Verify} />
                <Route
                  path="/form/form_test/:form_id/:test_id/:session_id"
                  component={Form}
                />
                <Route
                  path="/form/form_test/:form_id/:test_id"
                  component={Form}
                />
                <Route
                  path="/form/form_preview/:form_id/:projectVersion/:session_id"
                  component={FormPreview}
                />
                <Route
                  path="/form/form_preview/:form_id/:projectVersion"
                  component={Form}
                />
                <Route
                  path="/form/:form_id/:session_id/:project_session_id"
                  render={(params) => <FormWaiting isTest={false} {...params} />}
                />
                <Route
                  path="/form_test/:form_id/:session_id/:project_session_id/:test_id"
                  component={(params) => <FormWaiting isTest={true} {...params} />}
                />
                <Route path="/form/:form_id/:session_id" component={Form} />
                <Route path="/form/:form_id" component={Form} />
                <Route path="/form" component={Verify} />
                <Route path="/form_error/:error_type" component={FormError} />
                <Route path="/form_error" component={FormError} />
                <Route path="/bulk/bulk_test/:component_id/:test_id" component={BulkAssessment} />
                <Route path="/bulk/:component_id" component={BulkAssessment} />
              </Switch>
            </MuiThemeProvider>
          </Router>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
